import {
  MotifButton,
  MotifDropdownPortal,
  MotifFormField,
  MotifHeader,
  MotifHeaderLogo,
  MotifIcon,
  MotifIconButton,
  MotifPagination,
  MotifProgressBar,
  MotifSearch,
  MotifTabControl,
  MotifTabNavigation as MotifTabNav,
  MotifTheme
} from '@ey-xd/motif-react';
import { navigationIcClose24px, navigationIcExpandMore24px } from '@ey-xd/motif-react/assets/icons';
import React, { useEffect, useState } from 'react';
import FocusLock, { AutoFocusInside, InFocusGuard } from 'react-focus-lock';
import { useNavigate, useParams } from 'react-router-dom';
import EYLogo from '../assets/images/ey-logo.svg';
import CardComponent from '../components/card';
import CheckboxFilterComponent from '../components/checkboxFilter';
import FooterComponent from '../components/footer';
import HeroComponent from '../components/hero';
import MetaTags from '../components/meta';
import ModalWrapper from '../components/modalWrapper';
import { Cohort, PageContext, YearFilters } from '../interfaces';
import {
  filterByYear,
  filterCheckedValues,
  filterSearchTerms,
  getMaxPages,
  getPaginationBounds,
  getSelectedCohort
} from '../utils';
import './index.scss';

const eyUrl = 'https://www.ey.com';

interface IndexProps {
  pageData: PageContext;
  itemsPerPage?: number;
  searchAll?: boolean;
}

const IndexPage: React.FC<IndexProps> = ({ pageData, itemsPerPage = 20, searchAll = false }) => {
  const { cohortId } = useParams();
  const navigate = useNavigate();

  // initial data parsing and function creation
  const { cohorts, sectors, siteContent, years } = pageData;
  const {
    searchPlaceholder,
    siteTitle,
    heroTitle,
    heroImage,
    footnote,
    footerCTA,
    footerContent,
    footerLink,
    promoTab
  } = siteContent;


  const resetFilters = () => sectors.map(() => false);

  // state variables
  const [modalVisible, setModalVisibility] =
    useState<boolean>(getSelectedCohort({ value: cohortId, cohorts }).length > 0);
  const [selectedNode, setSelectedNode] =
    useState<Cohort[]>(getSelectedCohort({ value: cohortId, cohorts }));

  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [checkedFilters, setCheckedFilters] =
    useState<boolean[]>(resetFilters());

  const [filteredData, setFilteredData] = useState<Cohort[]>(cohorts);
  const [searchedData, setSearchedData] = useState<Cohort[]>(cohorts);

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<string | number>(1);
  const [fontsLoaded, setFontsLoaded] = useState<boolean>(false);

  const metadata = { ...siteContent,
    fullName: selectedNode.length ? selectedNode[0].fullName : null,
    company: selectedNode.length ? selectedNode[0].company : null,
    image: selectedNode.length ? selectedNode[0].headshot : null };

  // useEffect to list for change in cohortId - search params
  useEffect(() => {
    setModalVisibility(getSelectedCohort({ value: cohortId, cohorts }).length > 0);
    setSelectedNode(getSelectedCohort({ value: cohortId, cohorts }));
  }, [cohortId]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontsLoaded(true);
      document.body.classList.add('ean-app-loaded');
    });
  }, []);

  /**
  * handleInputChange - function that handles a search input change
  * @param searchValue: value to search against
  * Will reset values and subsequently set filtered data
  */
  const handleInputChange = (searchValue: string) => {
    const filterData: Cohort[] = filterSearchTerms({ value: searchValue, cohorts });
    setCurrentPage(1);
    setTabState(0);
    setCheckedFilters(resetFilters());
    setSearchInputValue(searchValue);
    setSearchedData(filterData);
    setFilteredData(filterData);
  };

  const getSearchStyle = (): Cohort[] => (searchAll ? cohorts : searchedData);

  /**
   *
   * @param value year to filter values by
   * @param idx tab index clicked
   * Will reset values and subsequently set filtered data
   */
  const handleTabControlClick = (value: string, idx: number) => {
    const filterData: Cohort[] = filterByYear({ value, cohorts: getSearchStyle() });

    setCurrentPage(1);
    setTabState(idx);
    setCheckedFilters(resetFilters());
    if (searchAll) {
      setSearchInputValue('');
    }
    setFilteredData(filterData);
  };

  /**
   *
   * @param idx sets active value
   * uses vanillaJS to parse through the dom and set the active class
   * addresses a flaw of motif.
   */
  const setTabState = (idx: number) => {
    Array.from(document.querySelectorAll('.motif-tab-button')).forEach((item, index) => {
      (idx === index) ? item.classList.add('motif-active') : item.classList.remove('motif-active');
    });
  };

  /**
   *
   * @param selectedIdx index to set checked value of checkbox cohorts
   * updates the checked filters array as well as resets the data as necessary.
   */
  const handleCheckboxChange = (selectedIdx: number) => {
    const updatedFilters = checkedFilters.map((value: boolean, idx: number) => (selectedIdx === idx ? !value : value));
    const filterData = filterCheckedValues({ cohorts: getSearchStyle(), updatedFilters, sectors });
    setCurrentPage(1);
    setTabState(0);
    if (searchAll) {
      setSearchInputValue('');
    }
    setCheckedFilters(updatedFilters);
    setFilteredData(filterData.length ? filterData : getSearchStyle());
  };

  /**
   * resets checked values in filter and restores all cohorts.
   */
  const handleResetButton = () => {
    setCurrentPage(1);
    setCheckedFilters(resetFilters());
    setFilteredData(cohorts);
    setSearchedData(cohorts);
  };

  /**
   *
   * @param id unique id to check against and display modal.
   * selects and navigates to the modal.
   */
  const handleShow = (id: string) => {
    setModalVisibility(true);
    setSelectedNode(getSelectedCohort({ value: id, cohorts }));
    navigate(`/cohort/${id}`);
  };

  /**
   * closes and navigates to the root url
   */
  const handleClose = () => {
    setModalVisibility(false);
    navigate(`/`);
    const { body } = document;
    body.style.overflow = 'auto';
  };

  /**
   * handles filter dropdown
   */
  const handleDropdown = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleClickOutside = () => {
    setDropdownIsOpen(false);
  };

  const handlePageChange = (val:string | number) => {
    window.scrollTo(0, 0);
    setCurrentPage(val);
  };

  /**
   *
   * @returns string
   *  utility to display selected cohorts proper verbiage
   */
  const getSelectedCount = () => `${filteredData.length} ${filteredData.length === 1 ? 'Cohort' : 'Cohorts'}`;

  /**
   *
   * @returns active filters selected
   */
  const getFilteredDropdownCount = () => {
    const count = checkedFilters.filter((value) => value).length;
    if (checkedFilters.filter((value) => value).length > 0) {
      return count && ` (${count})`;
    }
  };

  /**
   * utility to creates yearFilters array - needed to populate  tabset
   */
  const yearFilters: YearFilters[] = [{ label: 'ALL', value: '' }];
  if (promoTab !== 'None Active') {
    yearFilters.push({ label: promoTab, value: 'promoTab' });
  }

  years.forEach(node => {
    const { year } = node;
    yearFilters.push({ label: year, value: year });
  });

  /**
   * accessibility logic for filter dropdown on tablet and mobile
   */
  const ref = React.useRef(null);

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Escape') {
      setDropdownIsOpen(false);
    }
  };

  /**
   * determines click or touch event for tab filters
   */
  const supportsTouch = 'ontouchstart' in window;

  const getTouchTab = (value: string, idx: number) => {
    if (supportsTouch) {
      handleTabControlClick(value, idx);
    }
  };

  const getClickTab = (value: string, idx: number) => {
    if (!supportsTouch) {
      handleTabControlClick(value, idx);
    }
  };

  return (
    <>

      {!fontsLoaded && <div className="ean-progress-loader">
        <MotifProgressBar
          isIndeterminate
          circle
          hideLabel
        />
      </div>}
      <div className={`ean-app ${dropdownIsOpen ? 'no-scroll' : ''}`}>
        <MetaTags
          data={metadata} id={cohortId} />
        <MotifTheme variant="custom" />
        {/* HEADER */}
        <MotifHeader
          appHeaderName={siteTitle}
          fixed
          logo={
            <MotifHeaderLogo>
              <a href={eyUrl}>
                <img src={EYLogo} aria-hidden="true" alt="ey logo" />
                <span className="hide-for-accessibility">EY Logo - Home</span>
              </a>
            </MotifHeaderLogo>
          }
        />
        {/* HERO */}
        <HeroComponent bkgImage={heroImage}>
          <h1 className="ean-title">{heroTitle}</h1>
          <MotifFormField>
            <MotifSearch
              value={searchInputValue}
              items={[]}
              className="ean-search-input"
              title="search"
              aria-label="Search"
              onChange={(event: InputEvent) => {
                const searchTarget = event.target as HTMLInputElement;
                handleInputChange(searchTarget.value);
              }}
              placeholder={searchPlaceholder}
            />
          </MotifFormField>
          {/* Subnav Wrapper */}
          <div className="ean-subnav-wrapper">
            <div className="ean-aggregate">{getSelectedCount()}</div>
            <MotifDropdownPortal
              open={dropdownIsOpen}
              id="filter-dropdown"
              aria-labelledby="dropdown-trigger"
              className="ean-dropdown-menu"
              portalClassName="ean-dropdown-menu"
              handleClickOutside={handleClickOutside}
              onKeyDown={handleKeyDown}
              ref={ref}
              trigger={
                <MotifButton
                  variant="text"
                  type="button"
                  role="button"
                  onClick={handleDropdown}
                  id="dropdown-trigger"
                  className="ean-dropdown-trigger"
                  aria-haspopup="listbox"
                  aria-expanded={dropdownIsOpen}
                  aria-label="Filters">
                  <span>Filters {getFilteredDropdownCount()}</span>
                  <MotifIcon src={navigationIcExpandMore24px} />
                </MotifButton>
              }>
              <FocusLock shards={[ref]} disabled={!dropdownIsOpen}>
                <InFocusGuard>
                  <div className="ean-dropdown-reset-wrapper">
                    <div className="ean-dropdown-button-wrapper">
                      <span className="ean-dropdown-sm-filter">Filters {getFilteredDropdownCount()}</span>
                      <AutoFocusInside className="ean-dropdown-focus-wrapper">
                        <MotifButton
                          variant="text"
                          type="reset"
                          className="ean-reset-button"
                          tabIndex={0}
                          disabled={!checkedFilters.filter((value) => value).length}
                          onClick={() => { handleResetButton(); }}>Reset</MotifButton>
                      </AutoFocusInside>
                    </div>
                    <MotifIconButton
                      type="button"
                      onClick={() => setDropdownIsOpen(false)}
                      id="dropdown-close"
                      aria-label="Close Filter menu"
                      className="ean-close-filter-button"
                      tabIndex={0}
                      title="Close Filter menu"
                    >
                      <MotifIcon src={navigationIcClose24px} />
                    </MotifIconButton>
                  </div>
                  <div className="ean-filter-sm-options" role="listbox">
                    <CheckboxFilterComponent
                      checkedFilters={checkedFilters}
                      onChange={(idx: number) => {
                        handleCheckboxChange(idx);
                        setDropdownIsOpen(true);
                      }}
                      sectors={sectors}
                      cohorts={getSearchStyle()}
                      className="ean-dropdown-checkbox"
                      type="dropdown"
                    />
                  </div>
                  <div className="ean-apply-sm-filter">
                    <MotifButton
                      variant="primary-alt"
                      size="large"
                      tabIndex={0}
                      onClick={() => setDropdownIsOpen(false)}
                      disabled={checkedFilters.filter((value) => value).length === 0}>
                    Apply
                    </MotifButton>
                  </div>
                </InFocusGuard>
              </FocusLock>
            </MotifDropdownPortal>
          </div>
          {/* </div> */}
        </HeroComponent>
        {/* DIRECTORY BODY */}
        <div className="motif-container ean-content-container">
          <div className="motif-row">
            <div className="motif-col-xs-12 motif-col-lg-9">
              <MotifTabNav className="ean-tab-nav" defaultActiveKey={0}>
                {yearFilters.map((year:YearFilters, idx: number) => (
                  <MotifTabControl
                    controlled="true"
                    onClick={() => getClickTab(year.value, idx)}
                    key={idx}
                    onTouchEnd={() => getTouchTab(year.value, idx)}>
                    {year.label}
                  </MotifTabControl>)
                )}
              </MotifTabNav>
              {/* CARDS */}
              <div className="motif-row">
                {
                  filteredData.map((node: Cohort, idx: number) => {
                    let count = 0;
                    if (getPaginationBounds({ currentPage: Number(currentPage), itemsPerPage, idx })) {
                      count++;
                      const key = `${node.id}_${count}_${idx}`;

                      return <CardComponent
                        data={node}
                        key={key}
                        onClick={() => handleShow(node.id)}

                      />;
                    }

                    return false;
                  }
                  )}
                { filteredData.length === 0 ?
                  <div className="ean-no-results-container">
                    <h2>No result found</h2>
                    <p>We can’t find anyone matching your search.</p>
                  </div> : ''}
              </div>
              { getMaxPages({ amount: filteredData.length, itemsPerPage }) > 1 &&
            <div className="motif-row">
              <div className="motif-col-xs-12 ean-pagination-container">
                <MotifPagination
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  min={1}
                  max={getMaxPages({ amount: filteredData.length, itemsPerPage })}
                  inputProps={{
                    'aria-label': 'pagination-current-page'
                  }}
                  firstButtonProps={{
                    title: 'Go to first page'
                  }}
                  lastButtonProps={{
                    title: 'Go to last page'
                  }}
                  prevButtonProps={{
                    title: 'Go to previous page'
                  }}
                  nextButtonProps={{
                    title: 'Go to next page'
                  }} />
              </div>
            </div>
              }
            </div>
            {/* SIDEBAR - FILTERS */}
            <div className="motif-col-lg-3 ean-filter-hide">
              <h2 className="ean-filter-title">Filters</h2>
              <MotifButton
                variant="text"
                type="reset"
                className="ean-reset-button"
                disabled={!checkedFilters.filter((value) => value).length}
                onClick={() => { handleResetButton(); }}>Reset</MotifButton>
              <CheckboxFilterComponent
                checkedFilters={checkedFilters}
                onChange={(idx: number) => {
                  handleCheckboxChange(idx);
                  setDropdownIsOpen(true);
                }}
                sectors={sectors}
                cohorts={getSearchStyle()}
                className=""
                type="layout"
              />
            </div>
          </div>
        </div>

        <FooterComponent
          footerCTA={footerCTA}
          footerLink={footerLink}
          footerContent={footerContent}
          footnote={footnote} />
        {modalVisible &&
      <ModalWrapper
        handleClose={handleClose}
        modalVisible={modalVisible}
        data={selectedNode[0]}
      /> }
      </div>
    </>
  );
};

IndexPage.defaultProps = {
  itemsPerPage: 20,
  searchAll: false
};


export default IndexPage;
