import React from 'react';
import { Cohort } from '../../interfaces';
import CopyToClipboard from '../clipboard';
import './index.scss';
interface ModalContentTypes {
  data: Cohort;
}

const ModalContent: React.FC<ModalContentTypes> = ({ data }) => {
  const { title, company, fullName, city, state, headshot, bioContent, industry, companyWebsite, cohortYear, additional, id } = data;
  const removeHttp = () => (companyWebsite ? companyWebsite.replace(/^https?:\/\//, '') : null);
  const getIndustries = (value: string | string[]) => (typeof (value) === 'string' ?
    <p>{value}</p> :
    <p>{value.map((indus: string, index: number) => (index < (value.length - 1) ? `${indus}, ` : indus))}</p>);

  return (
    <div className="ean-modal">
      <div className="ean-modal-body">
        <div className="ean-modal-avatar">
          {headshot ?
            <img src={headshot} alt="profile" /> :
            <img src="../../assets/images/upload/headshot-placeholder.png" alt="placeholder" />}
        </div>
        <div className="ean-modal-personal-details">
          <h3 className="ean-modal-fullname">{fullName}</h3>
          <p className="ean-modal-content ean-modal-content-title">{title}</p>
          <p className="ean-modal-content">{company}</p>
          <p className="ean-modal-content">{city}, {state}</p>
          <CopyToClipboard id={id} />
        </div>
      </div>
      <hr />
      <div className="ean-modal-description-wrapper">
        <div className="ean-modal-description">
          <p>{bioContent}</p>
        </div>
        <div className="ean-modal-business-details">
          {cohortYear && <p className="ean-web-cohortyear">{cohortYear} Cohort</p>}
          {getIndustries(industry)}
          {removeHttp() && <p className="ean-web-copy">{removeHttp()}</p> }
          {additional && <p className="ean-web-additional">{additional}</p>}

        </div>
      </div>
    </div>
  );
};

export default ModalContent;
