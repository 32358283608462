import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import cohortsJSON from './data/cohorts.json';
import industryJSON from './data/industry.json';
import themeJSON from './data/theme.json';
import yearsJSON from './data/years.json';
import promoTabsJSON from './data/promoTabs.json';

import { PageContext, Years } from './interfaces';
import IndexPage from './templates';
import PageNotFound from './templates/404';
import { reverseYearOrder, transformIds } from './utils';

const yearsSorted:Years[] = reverseYearOrder(yearsJSON.data);

const pageData: PageContext = {
  cohorts: transformIds(cohortsJSON.data),
  years: yearsSorted,
  siteContent: themeJSON.data[0],
  sectors: industryJSON.data,
  promos: promoTabsJSON.data
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <IndexPage pageData={pageData} />
  },
  { path: '/cohort/:cohortId',
    element: <IndexPage pageData={pageData} /> },
  { path: '*',
    element: <PageNotFound data={pageData.siteContent} /> }
], {
  basename: process.env.PUBLIC_URL
});

const App: React.FC = () => (
  <div className="ean-app-root">
    <HelmetProvider context={{}}>
      <RouterProvider router={router} />
    </HelmetProvider>
  </div>
);

export default App;
