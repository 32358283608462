import { MotifIcon, MotifIconButton, MotifTooltip } from '@ey-xd/motif-react';
import { socialIcShare24px } from '@ey-xd/motif-react/assets/icons';
import React from 'react';
import { copyToClipboard } from '../../utils';
import './index.scss';
interface CopyToClipboardTypes {
  id: string;
}

const CopyToClipboard: React.FC<CopyToClipboardTypes> = ({ id }) => {
  const shareURL = `${window.location.origin}/cohort/${id}`;

  return (
    <>
      <MotifTooltip
        allowHover={false}
        placement="top"
        hideCloseButton
        variant="alt"
        trigger={
          <MotifIconButton
            aria-label="Copy link"
            type="button"
            className="ean-share-icon"
            onClick={() => {
              copyToClipboard(shareURL, () => false);
            }}>
            <MotifIcon src={socialIcShare24px} title="Icon Button" />
          </MotifIconButton>
        }
      >
        URL Copied to Clipboard
      </MotifTooltip>
    </>
  );
};

export default CopyToClipboard;
