import React from 'react';
import { Helmet } from 'react-helmet-async';
import { MetaData } from '../../interfaces';

interface MetaDataProps {
    data: MetaData;
    id?: string | null;
}

const MetaTags: React.FC<MetaDataProps> = ({ data, id }) => {
  const {
    mainMetaDescription,
    mainMetaKeywords,
    mainMetaTitle,
    cohortMetaDescription,
    cohortMetaKeywords,
    cohortMetaTitle,
    company,
    fullName,
    browserTitle,
    image
  } = data;
  let description:string | undefined;
  let title: string | undefined;
  let keywords:string | undefined;

  description = mainMetaDescription;
  title = mainMetaTitle;
  keywords = mainMetaKeywords;

  if (id) {
    description = cohortMetaDescription;
    title = `${cohortMetaTitle} ${fullName} - ${company}`;
    keywords = cohortMetaKeywords;
  }

  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>{browserTitle}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:url" content={image} />}
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content="EY" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      { /* End Twitter tags */ }
    </Helmet>
  ); };

MetaTags.defaultProps = {
  id: null
};

export default MetaTags;
