import { MotifButton, MotifFooter } from '@ey-xd/motif-react';
import React from 'react';
import EYLogo from '../../assets/images/ey-logo.svg';
import './index.scss';

interface FooterProps {
  footerCTA: string;
  footerContent: string;
  footerLink: string;
  footnote: string;
  full?: boolean;
}

const FooterComponent: React.FC<FooterProps> = ({ footerCTA, footerContent, footerLink, footnote, full }: FooterProps) => (
  <>

    <div className="ean-footer-container">
      {full && (
        <div className="ean-footer-banner">
          <div className="motif-container">
            <div className="motif-row motif-middle-xs">
              <div className="motif-col-xs-12 motif-col-md-4 motif-center-xs motif-start-md">
                <h2 className="ean-footer-banner-text">{footerContent}</h2>
              </div>
              <div className="motif-col-xs-12 motif-col-md-4 motif-center-xs motif-end-md ean-button">
                <MotifButton
                  size="large"
                  onClick={() => { window.open(footerLink, '_blank'); }}
                  className="ean-footer-button">{footerCTA}</MotifButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <MotifFooter className={`ean-footer ${!full && `ean-footer-slim`}`}>
        <a className="ean-footer-icon-link" href="https://www.ey.com/en_us">
          <img src={EYLogo} aria-hidden="true" className="ean-footer-icon" alt="EY logo" />
          <span className="hide-for-accessibility">EY Logo - Home</span>
        </a>
        {full && <p className="ean-footer-disclaimer">{footnote}</p> }
      </MotifFooter>
    </div>
  </>
);

FooterComponent.defaultProps = {
  full: true
};

export default FooterComponent;
