import { Cohort, Sectors, Years } from '../interfaces';

interface FilterTypes {
    value?: string;
    cohorts: Cohort[];
}

interface CheckedTypes {
  cohorts: Cohort[];
  sectors: Sectors[];
  updatedFilters: boolean[]
}

export const getIndustryCount = ({ value, cohorts }: FilterTypes) => cohorts?.filter(node => {
  if (typeof (node.industry) === 'string') {
    return node.industry === value;
  }

  return node.industry.find(industry => industry === value);
}).length;

export const filterSearchTerms = ({ value, cohorts }: FilterTypes) => cohorts.filter(node => {
  const { fullName, city,
    company,
    state,
    title } = node;

  const fullState = statesAndAbbreviations.find(item => state === item.abbreviation)?.name;

  if (value === '') {
    return node;
  }

  return value && (
    fullName.toLowerCase().includes(value.toLowerCase()) ||
      city.toLowerCase().includes(value.toLowerCase()) ||
      state.toLowerCase().includes(value.toLowerCase()) ||
      fullState?.toLowerCase().includes(value.toLowerCase()) ||
      title.toLowerCase().includes(value.toLowerCase()) ||
      company.toLowerCase().includes(value.toLowerCase()));
});

export const filterByYear = ({ value, cohorts }: FilterTypes) => {
  let filteredCohorts: Cohort[] = cohorts;

  if (value === '') {
    filteredCohorts = cohorts;
  }
  else if (value === 'promoTab') {
    filteredCohorts = cohorts.filter(node => node.promoTab);
  }
  else {
    filteredCohorts = cohorts.filter(node => node.cohortYear === value);
  }

  return filteredCohorts;
};

export const filterCheckedValues = ({ cohorts, sectors, updatedFilters }: CheckedTypes) => {
  const selectedSectors: string[] = sectors.filter((node: Sectors, idx: number) => updatedFilters[idx]).map((node => node.industryTitle));

  return cohorts.filter((cohort: Cohort) => {
    const { industry } = cohort;

    return selectedSectors.filter(sector => {
      if (typeof (industry) === 'string') {
        return sector === industry;
      }

      return industry.find(indus => sector === indus);
    })[0];
  });
};

export const getSelectedCohort = ({ value, cohorts }: FilterTypes) => cohorts.filter(node => node.id === value);

export const getMaxPages = ({ amount, itemsPerPage }: {amount: number, itemsPerPage: number}) =>
  (amount > 0 ? Math.ceil(amount / itemsPerPage) : 1);

export const getPaginationBounds = ({ currentPage, itemsPerPage, idx }: {currentPage: number, itemsPerPage: number, idx: number}) => {
  const min = (Number(currentPage) - 1) * itemsPerPage;
  const max = (Number(currentPage) * itemsPerPage);

  return idx >= min && idx < max;
};

export const statesAndAbbreviations = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PALAU', abbreviation: 'PW' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGIN ISLANDS', abbreviation: 'VI' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' }
];

export const transformIds = (cohorts:Cohort[]) => cohorts.map((cohort: Cohort) => ({
  ...cohort,
  id: `${cohort.cohortYear}-${cohort.id.replace('files__', '')}`
}));

export const copyToClipboard = (text: string, callback: () => void) => {
  navigator.clipboard.writeText(text).then(() => {
    callback();
  });
};

export const reverseYearOrder = (data: Years[]): Years[] => {
  const reversedYears = data.sort((p1, p2) => {
    if (p1.year < p2.year) {
      return 1;
    }

    return (p1.year > p2.year ? -1 : 0);
  });

  return reversedYears;
};
