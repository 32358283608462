import React, { useState } from 'react';
import './index.scss';

interface HeroTypes {
    children: React.ReactNode;
    bkgImage?: string | null;
}

const HeroComponent: React.FC<HeroTypes> = ({ children, bkgImage }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div className="ean-hero-container">
      <div className="ean-hero-content-container">
        <div className="motif-container">
          <div className="motif-row motif-bottom-xs">
            <div className="motif-col-xs-12 motif-col-lg-9">{children}</div>
          </div>
        </div>
      </div>
      <div
        className={`ean-hero-bkg-container ${loaded && `ean-hero-bkg-container-loaded`}`}
        style={{ backgroundImage: loaded ? `url(${bkgImage})` : 'transparent' }} />
      {bkgImage && <img
        src={bkgImage}
        alt="background"
        className="ean-hero-bkg-loader"
        onLoad={() => { setLoaded(true); }}
      />}
    </div>
  );
};

HeroComponent.defaultProps = {
  bkgImage: null
};

export default HeroComponent;
