import { MotifCard, MotifCardBody } from '@ey-xd/motif-react';
import React from 'react';
import { Cohort } from '../../interfaces';
import './index.scss';

interface CardProps {
   data: Cohort;
   onClick: () => void;
}

const CardComponent: React.FC<CardProps> = ({ data, onClick }) => {
  const { title, company, fullName, city, state, headshot } = data;

  return (<div
    className="motif-col-xs-4 motif-col-sm-4 motif-col-md-4 motif-col-lg-6 ean-card-wrapper"
  >
    <button onClick={onClick} className="ean-reset-button ean-card-button">
      <MotifCard className="ean-card">
        <MotifCardBody className="ean-card-body">
          <div className="ean-card-avatar">
            {headshot ?
              <img src={headshot} alt="profile" /> :
              <img src="../../assets/images/upload/headshot-placeholder.png" alt="placeholder" />}

          </div>
          <div className="ean-card-description">
            <h2 className="ean-card-content ean-card-content-name">{fullName}</h2>
            <p className="ean-card-content ean-card-content-title">{title}</p>
            <p className="ean-card-content">{company}</p>
            <p className="ean-card-content">{city}, {state}</p>
          </div>

        </MotifCardBody>
      </MotifCard>
    </button>
  </div>);
};


export default CardComponent;
