import { MotifCheckbox, MotifFormField } from '@ey-xd/motif-react';
import React from 'react';
import { Cohort, Sectors } from '../../interfaces';
import { getIndustryCount } from '../../utils';
import './index.scss';

export interface CheckboxProps {
  checkedFilters: boolean[];
  onChange: (idx: number) => void;
  cohorts: Cohort[];
  sectors: Sectors[];
  className?: string | null;
  type: string
}

const CheckboxFilterComponent: React.FC<CheckboxProps> = ({ checkedFilters,
  onChange,
  cohorts,
  sectors,
  className,
  type }) => <>
  {sectors.sort((a, b) => {
    if (a.industryTitle < b.industryTitle) {
      return -1;
    }
    if (a.industryTitle > b.industryTitle) {
      return 1;
    }

    return 0;
  }).map((node: Sectors, idx: number) => {
    const { industryTitle } = node;
    const count: number = getIndustryCount({ value: industryTitle, cohorts });
    const id = `${industryTitle.toLowerCase().replaceAll(' ', '-')}-${type}`;

    return (count > 0 && <MotifFormField key={idx}>
      <MotifCheckbox
        tabIndex={0}
        role="checkbox"
        value={industryTitle}
        name={industryTitle}
        checked={checkedFilters[idx]}
        id={id}
        aria-checked={checkedFilters[idx]}
        onChange={() => { onChange(idx); }}
        className={className ? className : ''}
      >
        {industryTitle} {`(${count})`}
      </MotifCheckbox>
    </MotifFormField>);
  })}
</>;

CheckboxFilterComponent.defaultProps = {
  className: null
};
export default CheckboxFilterComponent;
